import { useQuery } from '@tanstack/react-query';
import { startOfMonth } from 'date-fns';
import { useEffect, useState } from 'react';
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { Content } from 'types/Content';
import { Folder } from 'types/Folder';
import Navbar from './components/Navbar';
import TypeSelector from './components/TypeSelector';
import Context from './Context';
import useRouteIsBlocked from './hooks/useRouteIsBlocked';
import { Route } from './interfaces/route';
import getContent from './services/getContent';
import getFolder from './services/getFolder';

const allStatesValue = 'all';

const VoDTemplate = ({ routes }: { routes: Route[] }) => {
  const { id } = useParams();
  const [params] = useSearchParams();
  const [itemId, setItemId] = useState<string>(id as string);
  const [selectedAccountId, setSelectedAccountId] = useState<string>('');
  const [paths, setPaths] = useState<string>(params.get('paths') as string);
  const { search } = useLocation();
  const navigate = useNavigate();
  const routeIsBlocked = useRouteIsBlocked(routes);
  const isDir = params.get('isDir') === 'true';
  const [dateRange, setDateRange] = useState({
    startDate: startOfMonth(new Date()),
    endDate: new Date(),
  });
  const [selectedState, setSelectedState] = useState(allStatesValue);
  const { data: item } = useQuery(['item', itemId], async () => {
    const request = isDir ? getFolder : getContent;
    return await request(itemId);
  });
  useEffect(() => {
    if (routeIsBlocked) {
      navigate(`/vod/${itemId}/general${search}`);
    }
  }, [routeIsBlocked]);
  const itemInfo = item as Content & Folder;
  const itemName = isDir ? itemInfo?.name : itemInfo?.title;

  return (
    <Context.Provider
      value={{
        allStatesValue,
        itemId,
        isDir,
        dateRange,
        paths,
        selectedAccountId,
        selectedState,
        setDateRange,
        setItemId,
        setSelectedAccountId,
        setSelectedState,
        setPaths,
      }}
    >
      <Navbar title={itemName || '...'} />
      <TypeSelector />
      <Outlet />
    </Context.Provider>
  );
};

export default VoDTemplate;
