import {
  Autocomplete,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Context as MeContext } from 'components/MeProvider/MeProvider';
import Context from 'modules/VoD/Context';
import { Client } from 'modules/VoD/interfaces/Client';
import { useContext } from 'react';
import { getClients, getTeams } from '../services';

interface ClientFilterProps {
  selectedClient: Client | null;
  setSelectedClient: (client: Client | null) => void;
  teamId: string;
  setTeamId: (teamId: string) => void;
}

const ClientFilter = ({
  selectedClient,
  setSelectedClient,
  teamId,
  setTeamId,
}: ClientFilterProps) => {
  const { itemId, paths, setSelectedAccountId, setItemId, setPaths } =
    useContext(Context);
  const { me } = useContext(MeContext);
  const { isLoading, data: clients } = useQuery({
    queryKey: ['clients'],
    queryFn: getClients,
  });
  const { data: teams } = useQuery({
    queryKey: [selectedClient?.conta_id],
    queryFn: async () => await getTeams(selectedClient?.conta_id as string),
    enabled: !!selectedClient,
  });

  const changeURLPath = (folderId: string) => {
    const changeURLId = window.location.href.replace(itemId, folderId);
    const changeURLPaths = changeURLId.replace(paths, `/${folderId}`);
    window.history.replaceState({ folderId }, '', changeURLPaths);
    setItemId(folderId);
    setPaths(`/${folderId}`);
  };

  const handleClientChange = (_: any, client: Client | null) => {
    setSelectedClient(client);
    setTeamId('');
    setSelectedAccountId(client?.conta_id || '');
    changeURLPath(client?.diretorio_raiz || me.user.diretorioRoot);
  };

  const getClientByAccountId = (clientId: Client['conta_id']) =>
    clients?.find((client) => client.conta_id === clientId) ?? null;

  const getTeamById = (teamId: string) =>
    teams?.items.find((currentTeam) => currentTeam.accountId === teamId) ??
    null;

  const changeTeamId = (event: SelectChangeEvent<string>) => {
    if (event.target.value === 'default') {
      setTeamId('');
      setSelectedAccountId(selectedClient?.conta_id as string);
      changeURLPath(selectedClient?.diretorio_raiz || '');
      return;
    }

    setTeamId(event.target.value);
    changeURLPath(getTeamById(event.target.value)?.rootFolderId || '');
    setSelectedAccountId(event.target.value);
  };

  const shouldShowTeamList = selectedClient && teams && !!teams.items.length;
  return (
    <>
      <Autocomplete
        getOptionLabel={(client) => client.nome_cliente}
        disabled={isLoading}
        noOptionsText="Sem resultados"
        onChange={handleClientChange}
        options={clients ?? []}
        renderInput={(params) => (
          <TextField {...params} placeholder="Selecione" />
        )}
        sx={{
          bgcolor: 'neutral.light',
          fieldset: { display: 'none' },
          height: 37,
          width: 220,
        }}
        value={getClientByAccountId(selectedClient?.conta_id as string)}
      />
      {shouldShowTeamList && (
        <>
          <Typography
            sx={{ color: 'neutral.body', fontSize: 12, mb: 2, mt: 4 }}
          >
            Selecionar time
          </Typography>
          <Select
            value={teamId || 'default'}
            sx={{
              bgcolor: 'neutral.light',
              height: 40,
              fieldset: { display: 'none' },
              width: 208,
            }}
            onChange={changeTeamId}
          >
            <MenuItem value="default">Selecione</MenuItem>
            {teams.items.map((team) => (
              <MenuItem key={team.accountId} value={team.accountId}>
                {team.name}
              </MenuItem>
            ))}
          </Select>
        </>
      )}
    </>
  );
};

export default ClientFilter;
