import { axios } from 'helpers/axios/axios';
import { DateRange } from '../types';
import formatBaseURLWithDateRange from '../utils/formatBaseURLWithDateRange';

export interface TotalActivatedSearch {
  percentage: number;
}

export interface TotalActivatedSearchProps {
  itemId: string;
  dateRange: DateRange;
  selectedState: string;
  isDir: boolean;
  accountId: string;
}

const getPercentageActivatedSearch = async ({
  itemId,
  dateRange,
  selectedState,
  isDir,
  accountId,
}: TotalActivatedSearchProps): Promise<TotalActivatedSearch> => {
  const dateFilter = formatBaseURLWithDateRange(dateRange);
  const baseURL = `/v1/analytics`;
  const fullUrl = isDir
    ? `${baseURL}/directory/total-percentage-activated-search${dateFilter}&region=${selectedState}&directory=${itemId}&accountId=${accountId}`
    : `${baseURL}/vod/${itemId}/total-percentage-activated-search${dateFilter}&region=${selectedState}`;
  const { data: TotalActivatedSearch } = await axios.get(fullUrl);
  return TotalActivatedSearch as TotalActivatedSearch;
};

export default getPercentageActivatedSearch;
