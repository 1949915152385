import { FilterAlt as FilterAltIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { AuthorizedSection } from '@tshio/react-router-permissions';
import DateRangePickerComponent from 'components/DatePicker/DateRangePicker';
import ClientFilter from './ClientFilter';
import { BRAZIL_STATES } from 'consts/geoOptions';
import Context from 'modules/VoD/Context';
import { Client } from 'modules/VoD/interfaces/Client';
import { MouseEvent, useContext, useState } from 'react';

const Filters = () => {
  const {
    allStatesValue,
    dateRange,
    selectedState,
    setSelectedAccountId,
    setSelectedState,
    setDateRange,
  } = useContext(Context);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [teamId, setTeamId] = useState('');

  const resetFilter = () => {
    setDateRange({ startDate: new Date(), endDate: new Date() });
    setSelectedState(allStatesValue);
    setSelectedClient(null);
    setTeamId('');
    setSelectedAccountId('');
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <Button
        variant="outlined"
        onClick={(event: MouseEvent<HTMLButtonElement>) =>
          setAnchorEl(event.currentTarget)
        }
      >
        <FilterAltIcon />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box sx={{ bgcolor: 'common.white', p: 4 }}>
          <Typography sx={{ color: 'neutral.body', fontSize: 12, mb: 2 }}>
            Selecionar período
          </Typography>
          <DateRangePickerComponent
            dateRange={dateRange}
            changeDateRange={setDateRange}
          />
          <AuthorizedSection requires="spalla-dev">
            {({ isAuthorized }: { isAuthorized: boolean }) =>
              isAuthorized && (
                <>
                  <Typography
                    sx={{ color: 'neutral.body', fontSize: 12, mb: 2, mt: 4 }}
                  >
                    Selecionar cliente
                  </Typography>
                  <ClientFilter
                    selectedClient={selectedClient}
                    setSelectedClient={setSelectedClient}
                    teamId={teamId}
                    setTeamId={setTeamId}
                  />
                </>
              )
            }
          </AuthorizedSection>

          <Typography
            sx={{ color: 'neutral.body', fontSize: 12, mb: 2, mt: 4 }}
          >
            Selecionar estado
          </Typography>
          <Select
            name="states"
            value={selectedState}
            onChange={(event: SelectChangeEvent) =>
              setSelectedState(event.target.value)
            }
            sx={{
              bgcolor: 'neutral.light',
              fieldset: { display: 'none' },
              fontSize: 'body3',
              height: 37,
              width: '100%',
            }}
          >
            <MenuItem
              key={allStatesValue}
              value={allStatesValue}
              sx={{ fontSize: 'body3' }}
            >
              Todos
            </MenuItem>
            {BRAZIL_STATES.map((state: any) => (
              <MenuItem
                key={state.value}
                value={state.value}
                sx={{ fontSize: 'body3' }}
              >
                {state.label}
              </MenuItem>
            ))}
          </Select>
          <Button
            sx={{
              display: 'block',
              fontSize: 'body2',
              mt: 4,
              width: '100%',
            }}
            onClick={resetFilter}
          >
            Limpar filtros
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default Filters;
