import { axios } from 'helpers/axios/axios';
import { Directory } from 'types/Directory';

const getDirectories = async (
  title: string,
  directoryId: string,
  accountId: string,
): Promise<Directory> =>
  await axios
    .get(
      `/v1/directories?name=${title}&directory=${directoryId}&accountId=${accountId}`,
    )
    .then(({ data }) => data);

export default getDirectories;
