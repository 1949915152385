import { axios } from 'helpers/axios/axios';

interface TotalCountVoD {
  total: number;
}

interface getTotalCountVodProps {
  accountId: string;
  directoryId: string;
}

const getTotalCountVod = async ({
  accountId,
  directoryId,
}: getTotalCountVodProps): Promise<TotalCountVoD> => {
  const baseURL = `/v1/analytics/video-count?directoryId=${directoryId}&accountId=${accountId}`;
  const { data: totalCountVoD } = await axios.get(baseURL);
  return totalCountVoD;
};

export default getTotalCountVod;
