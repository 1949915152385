import { axios } from 'helpers/axios/axios';
import { DateRange } from '../../../types';
import formatBaseURLWithDateRange from '../../../utils/formatBaseURLWithDateRange';

export interface FolderInfo {
  consumption: {
    storageMinutes: number;
    transcodeMinutes: number;
  };
  totalViews: {
    total: number;
  };
  // totalFilesUploaded: {
  //   total: number;
  // };
  // totalVideoReplacements: {
  //   total: number;
  // };
}

const getFolderInfo = async (
  itemId: string,
  dateRange: DateRange,
  selectedState: string,
  accountId: string,
): Promise<FolderInfo> => {
  const dateFilter = formatBaseURLWithDateRange(dateRange);
  const dateWithNoDayFilter = formatBaseURLWithDateRange(dateRange, 'MM/yyyy');
  const baseURL = `/v1/analytics/directory`;
  const urlWithDates = `${dateFilter}&region=${selectedState}`;
  const dateWithNoInterrogation = dateWithNoDayFilter.replaceAll('?', '');
  const { data: consumption } = await axios.get(
    `/v1/analytics/directory/consumed?directory=${itemId}&${dateWithNoInterrogation}`,
  );
  const { data: totalViews } = await axios.get(
    `${baseURL}/total-views${urlWithDates}&directory=${itemId}&accountId=${accountId}`,
  );
  // const { data: totalFilesUploaded } = await axios.get(
  //   `${baseURL}/total-files-uploaded${urlWithDates}&folder=${itemId}`,
  // );
  // const { data: totalVideoReplacements } = await axios.get(
  //   `${baseURL}/total-video-replacements${urlWithDates}&folder=${itemId}`,
  // );
  return {
    totalViews,
    consumption,
    // totalFilesUploaded,
    // totalVideoReplacements,
  };
};

export default getFolderInfo;
