import { ArrowBackRounded as ArrowBackRoundedIcon } from '@mui/icons-material';
import {
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { SUBDOMAIN } from 'consts/subdomain';
import { ReactNode, useContext } from 'react';
import Context from '../../Context';
import ChooseFolder from './components/ChooseFolder';
import Filters from './components/Filters';

interface Props {
  title: string | ReactNode;
}

const Navbar = ({ title }: Props) => {
  const { isDir } = useContext(Context);
  const backToVodLibrary = () =>
    window.location.replace(`${SUBDOMAIN}/vod/dashboard`);

  return (
    <Card sx={{ height: 70, mb: 4 }}>
      <CardContent
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Stack alignItems="center" direction="row" gap={5}>
          <IconButton onClick={backToVodLibrary}>
            <ArrowBackRoundedIcon />
          </IconButton>
          <Typography
            variant="body1"
            sx={{
              color: 'neutral.body',
              fontWeight: 600,
              maxWidth: '45vw',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {title}
          </Typography>
        </Stack>
        <Stack alignItems="center" direction="row" gap={4}>
          <Filters />
          {isDir && <ChooseFolder />}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default Navbar;
