import { useQuery } from '@tanstack/react-query';
import {
  EventRounded as EventRoundedIcon,
  FileUpload as FileUploadIcon,
  OndemandVideo as OndemandVideoIcon,
  RemoveRedEyeRounded as EyeIcon,
  TouchAppRounded as TouchAppIcon,
} from '@mui/icons-material';
import { Grid, SxProps, Theme } from '@mui/material';
import { formatDistance } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { ReactNode, useContext, useMemo } from 'react';
import MetricsCard from '../../components/MetricsCard';
import MetricsCardSkeleton from '../../components/MetricsCard/MetricsCardSkeleton';
import SubtitleActivationPercentageCard from '../../components/SubtitleActivationPercentageCard';
import SubtitleActivationSkeleton from '../../components/SubtitleActivationSkeleton';
import Context from '../../Context';
import getPercentageActivatedSearch from '../../services/getPercentageActivatedSearch';
import formatIntervalDuration from '../../utils/formatIntervalDuration';
import ConsumptionCard from './components/ConsumptionCard';
import ConsumptionCardSkeleton from './components/ConsumptionCardSkeleton';
import MapChart from './components/MapChart';
import getContentInfo, { ContentInfo } from './services/getContentInfo';
import getFolderInfo, { FolderInfo } from './services/getFolderInfo';
import getTotalCountVod from './services/getTotalCountInfo';
import getFormatedNumber from './utils/formatNumber';

const GetMetricCards = ({
  title,
  icon,
  value,
  disabled,
  sx,
  isLoading,
}: {
  title: string;
  icon: ReactNode;
  value: string | number;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  isLoading: boolean;
}) => {
  if (isLoading) {
    return <MetricsCardSkeleton sx={sx} />;
  }
  return (
    <MetricsCard
      title={title}
      icon={icon}
      value={value}
      disabled={disabled}
      sx={sx}
    />
  );
};

const General = () => {
  const {
    allStatesValue,
    itemId,
    isDir,
    dateRange,
    selectedState,
    selectedAccountId,
  } = useContext(Context);
  const newSelectedState =
    selectedState === allStatesValue ? '' : selectedState;
  const { isLoading: isLoadingContentInfo, data: contentInfo } = useQuery(
    [
      'vodGeneral',
      itemId,
      dateRange.startDate.toISOString(),
      dateRange.endDate.toISOString(),
      selectedState,
    ],
    async () => {
      const request = isDir
        ? getFolderInfo(itemId, dateRange, newSelectedState, selectedAccountId)
        : getContentInfo(itemId, dateRange, newSelectedState);
      return await request;
    },
  );
  const { isLoading: isLoadingActivatedSearch, data: totalActivatedSearch } =
    useQuery(
      [
        'activatedSearch',
        itemId,
        dateRange.startDate.toISOString(),
        dateRange.endDate.toISOString(),
        selectedState,
      ],
      async () =>
        await getPercentageActivatedSearch({
          itemId,
          dateRange,
          selectedState: newSelectedState,
          isDir,
          accountId: selectedAccountId,
        }),
    );
  const { data: totalCountVoD, isLoading: loadingCountTotalVod } = useQuery(
    ['totalCount', itemId],
    async () =>
      await getTotalCountVod({
        directoryId: itemId,
        accountId: selectedAccountId,
      }),
  );
  const transcodeMinutes = useMemo(
    () =>
      formatIntervalDuration(contentInfo?.consumption.transcodeMinutes ?? 0),
    [contentInfo],
  );
  const storageMinutes = useMemo(
    () => formatIntervalDuration(contentInfo?.consumption.storageMinutes ?? 0),
    [contentInfo],
  );
  const isLoading =
    (isLoadingContentInfo && contentInfo === undefined) ||
    loadingCountTotalVod ||
    !totalCountVoD;
  const content = contentInfo as ContentInfo & FolderInfo;
  let lastViewLabel = '-';
  if (!isLoading) {
    const hasValidLastView = !isDir && !!content.lastView;
    if (hasValidLastView) {
      const trackedAt = new Date(content.lastView.trackedAt);
      const currentDate = Date.now();
      const timeDistance = formatDistance(trackedAt, currentDate, {
        locale: ptBR,
      });
      const isDefaultDate =
        !content.lastView.trackedAt ||
        content.lastView.trackedAt === '0001-01-01T00:00:00Z';
      const formattedTimeDistance = timeDistance?.replace('cerca de ', '');
      lastViewLabel = isDefaultDate ? '-' : `${formattedTimeDistance} atrás`;
    }
  }
  return (
    <Grid container sx={{ mt: 4 }}>
      <Grid container gap={4} desktop sx={{ height: 'max-content' }}>
        {!isDir && (
          <GetMetricCards
            isLoading={isLoading}
            title="Última visualização"
            icon={<EventRoundedIcon />}
            value={lastViewLabel}
          />
        )}
        {isLoadingActivatedSearch ? (
          <SubtitleActivationSkeleton />
        ) : (
          <SubtitleActivationPercentageCard data={totalActivatedSearch} />
        )}
        {isLoading ? (
          <ConsumptionCardSkeleton />
        ) : (
          <ConsumptionCard
            transcoding={transcodeMinutes}
            storage={storageMinutes}
          />
        )}
        <GetMetricCards
          isLoading={isLoading}
          title="Visualizações"
          icon={<EyeIcon />}
          value={getFormatedNumber(content?.totalViews?.total)}
          sx={{ order: isDir ? -3 : 'unset' }}
        />
        {!isDir && (
          <GetMetricCards
            isLoading={isLoading}
            title="Impressões"
            icon={<TouchAppIcon />}
            value={content?.impressions?.total}
          />
        )}
        {isDir && (
          <>
            <GetMetricCards
              isLoading={isLoading}
              title="Total de Vídeos"
              icon={<OndemandVideoIcon />}
              value={getFormatedNumber(totalCountVoD?.total ?? 0)}
            />
            <GetMetricCards
              isLoading={isLoading}
              title="Substituições de vídeo"
              icon={<FileUploadIcon />}
              value=""
              disabled
            />
          </>
        )}
      </Grid>
      <Grid item desktop>
        <MapChart isDir={isDir} />
      </Grid>
    </Grid>
  );
};

export default General;
