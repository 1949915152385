import { axios } from 'helpers/axios/axios';
import { Client } from 'modules/VoD/interfaces/Client';
import { Team } from 'modules/VoD/interfaces/Team';

export const getClients = async (): Promise<Client[]> =>
  await axios.get('/bff/cliente?subConta=false').then(({ data }) => data);
export const getTeams = async (
  clientId: Client['conta_id'],
): Promise<{ items: Team[] }> =>
  await axios
    .get(`/v2/accounts?accountId=${clientId}`)
    .then(({ data }) => data);
