import {
  CloseRounded as CloseIcon,
  OndemandVideo as LibraryIcon,
} from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useContext } from 'react';
import getDirectories from 'services/getDirectories';
import Context from '../../../Context';
import DirectoryItem from './DirectoryItem';

interface ModalToChooseFolderProps {
  open: boolean;
  onClose: () => void;
}

const ModalToChooseFolder = ({ open, onClose }: ModalToChooseFolderProps) => {
  const { itemId, setItemId, paths, selectedAccountId, setPaths } =
    useContext(Context);
  const changeSelectedFolderId = useCallback(
    (id: string, normalizedFullPath: string) => {
      const changeURLId = window.location.href.replace(itemId, id);
      const changeURLPaths = changeURLId.replace(paths, normalizedFullPath);
      window.history.replaceState({ id }, '', changeURLPaths);
      setItemId(id);
      setPaths(normalizedFullPath);
    },
    [itemId, paths],
  );
  const { isLoading, data } = useQuery(
    ['getDirectories', selectedAccountId],
    async () => await getDirectories('', '', selectedAccountId),
  );
  const rootFolderId = data?.rootFolderId;
  const rootFolderIsSelected = itemId === rootFolderId;
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          bgcolor: 'common.white',
          boxShadow: 24,
          height: 438,
          left: '50%',
          position: 'absolute',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          width: 677,
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{ p: 5 }}
        >
          <Typography sx={{ color: 'neutral.main', fontSize: 16 }}>
            Acessar pasta
          </Typography>
          <IconButton onClick={onClose} sx={{ p: 0 }}>
            <CloseIcon sx={{ color: 'muted' }} />
          </IconButton>
        </Stack>
        <Divider sx={{ mb: 3 }} />
        {isLoading || !data ? (
          <Stack alignItems="center" sx={{ width: '100%' }}>
            <CircularProgress />
          </Stack>
        ) : (
          <Box sx={{ height: 270, p: 4, pt: 0, overflowY: 'auto' }}>
            <Stack
              alignItems="center"
              direction="row"
              gap={2}
              sx={{
                bgcolor: rootFolderIsSelected ? 'primary.50' : 'none',
                borderRadius: 1,
                cursor: 'pointer',
                height: 40,
                mb: 2,
                pl: 3,
              }}
              onClick={() =>
                changeSelectedFolderId(
                  data.rootFolderId,
                  data.fileMap[data.rootFolderId].normalizedFullPath,
                )
              }
            >
              <LibraryIcon
                sx={{
                  color: rootFolderIsSelected
                    ? 'primary.main'
                    : 'neutral.muted',
                }}
              />
              <Typography
                sx={{
                  color: rootFolderIsSelected ? 'primary.main' : 'neutral.body',
                  fontSize: 14,
                }}
              >
                Geral
              </Typography>
            </Stack>
            <DirectoryItem
              id={data.rootFolderId}
              selectedFolderId={itemId}
              onChangeSelectedFolderId={changeSelectedFolderId}
              root={data}
            />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default ModalToChooseFolder;
