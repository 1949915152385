/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createContext } from 'react';
import { DateRange } from './types';

interface ContextType {
  allStatesValue: string;
  itemId: string;
  isDir: boolean;
  dateRange: DateRange;
  paths: string;
  selectedState: string;
  selectedAccountId: string;
  setDateRange: (dates: DateRange) => void;
  setItemId: (id: string) => void;
  setSelectedState: (state: string) => void;
  setSelectedAccountId: (id: string) => void;
  setPaths: (paths: string) => void;
}

const Context = createContext({} as ContextType);

export default Context;
